import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap'; // Added Nav here
import { Link } from 'react-router-dom';
import '../styles/Navbar.css'; // Adjusted path to import CSS correctly
import logo from '../images/icon.png'; // Adjust the path to your image file

const MyNavbar = () => {
  return (
    <Navbar expand="lg">
      <Container className='container-navbar'>
        <Nav.Link as={Link} to="/">
          <div className="navbar-title">
            <img src={logo} alt="Logo" className="navbar-logo" />
            <h1>MamuJuega | Game Studies Diary</h1>
          </div>
        </Nav.Link>
      </Container>
    </Navbar>
  );
};

export default MyNavbar;
