import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Card, Container, Row, Col, Button } from 'react-bootstrap';
import '../styles/Posts.css';
import '../styles/breakpoints.css';

const Posts = () => {
  const [posts, setPosts] = useState([]);
  const [visiblePosts, setVisiblePosts] = useState(6);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios.get('https://www.mamujuega.com/cms/wp-json/wp/v2/posts')
      .then(response => {
        setPosts(response.data);
      })
      .catch(error => {
        console.error('Error fetching posts:', error);
      });
  }, []);

  const loadMorePosts = () => {
    setLoading(true);
    setTimeout(() => {
      setVisiblePosts(prevVisiblePosts => prevVisiblePosts + 6);
      setLoading(false);
    }, 500);
  };

  return (
    <Container className="container-posts">
      <Row className="posts-section">
        <Col md={12}>
          <h2 className='posts-subtitle'>Posts</h2>
        </Col>
        {posts.slice(0, visiblePosts).map(post => (
          <Col key={post.id} lg={4} md={6} sm={6} className="mb-4">
            <Card>
              <Card.Body>
                <Card.Title>{post.title.rendered}</Card.Title>
                <Card.Text>
                  <div dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
                </Card.Text>
                <Link to={`/post/${post.id}`} className='card-link'>
                  <p className='button-text'>Read more</p>
                </Link>
              </Card.Body>
            </Card>
          </Col>
        ))}
        {visiblePosts < posts.length && (
          <Col md={12} className="text-center mb-4">
            <Button onClick={loadMorePosts} disabled={loading}>
              {loading ? 'Loading...' : 'Load More Posts'}
            </Button>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Posts;
