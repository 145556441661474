import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Card, Container, Row, Col } from 'react-bootstrap';
import '../styles/Home.css';
import '../styles/breakpoints.css';

const Home = () => {
  const [posts, setPosts] = useState([]);
  const [mastodonFeed, setMastodonFeed] = useState([]);
  const [showAllPosts, setShowAllPosts] = useState(false);

  useEffect(() => {
    axios.get('https://www.mamujuega.com/cms/wp-json/wp/v2/posts')
      .then(response => {
        setPosts(response.data);
      })
      .catch(error => {
        console.error('Error fetching posts:', error);
      });
  }, []);

  useEffect(() => {
    axios.get('https://mastodon.social/api/v1/accounts/111160988630310680/statuses')
      .then(response => {
        setMastodonFeed(response.data);
      })
      .catch(error => {
        console.error('Error fetching Mastodon feed:', error);
      });
  }, []);

  return (
    <Container className="container-home">
      <Row className="posts-section">
        <Col md={12}>
          <h2 className='home-subtitle'>Posts</h2>
        </Col>
        {posts.slice(0, showAllPosts ? posts.length : 3).map(post => (
          <Col key={post.id} lg={4} md={6} sm={6} className="mb-4">
            <Card>
              <Card.Body>
                <Card.Title>{post.title.rendered}</Card.Title>
                <Card.Text>
                  <div className="card-text" dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
                </Card.Text>
                <Link to={`/post/${post.id}`} className='card-link'>
                  <p className='button-text'>Read more</p>
                </Link>
              </Card.Body>
            </Card>
          </Col>
        ))}
        <Col md={12} className="see-all-posts-container">
          <Link to="/posts" className="see-all-posts">See all posts</Link>
        </Col>
      </Row>
      <Row className="social-section">
        <Col md={12}>
          <h2 className='home-subtitle'>Social</h2>
        </Col>
        <Col md={12} className="social-feed">
          <div className="mastodon-feed">
            {mastodonFeed.map(status => (
              <Card key={status.id} className="mb-4 mastodon-card">
                <Card.Body>
                  <Card.Text>
                    <div dangerouslySetInnerHTML={{ __html: status.content }} />
                  </Card.Text>
                </Card.Body>
              </Card>
            ))}
          </div>
        </Col>
        <Col md={12} className="go-to-mastodon-container">
          <a href="https://mastodon.social/@mamupelaa" className="go-to-mastodon">Go to Mastodon</a>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
