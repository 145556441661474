import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/breakpoints.css';
import Home from './pages/Home';
import Post from './pages/Post';
import Posts from './pages/Posts'; // Import the Posts component
import Navbar from './components/Navbar'; // Ensure this path is correct
import { initGA } from './analytics';
import useAnalytics from './hooks/useAnalytics';

const App = () => {
  useEffect(() => {
    initGA();
  }, []);

  useAnalytics();

  return (
    <>
      <Navbar />
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/post/:id" element={<Post />} />
          <Route path="/posts" element={<Posts />} /> {/* Add the new route for Posts */}
        </Routes>
      </div>
    </>
  );
};

export default App;
