import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Container, Spinner } from 'react-bootstrap';
import p5 from 'p5';
import '../styles/Post.css';

const Post = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    axios.get(`https://www.mamujuega.com/cms/wp-json/wp/v2/posts/${id}`)
      .then(response => {
        setPost(response.data);
      })
      .catch(error => {
        console.error('Error fetching post:', error);
      });
  }, [id]);

  useEffect(() => {
    const sketch = (p) => {
      let forms = [];

      p.setup = () => {
        p.createCanvas(p.windowWidth, p.windowHeight);
        p.noStroke(); // Ensure no borders for shapes
        for (let i = 0; i < 5; i++) {
          forms.push(new Form(p.random(p.width), p.random(p.height), p.random(100, 500), p));
        }
      };

      p.draw = () => {
        p.background(255); // Set background to white
        forms.forEach(form => form.display());
        p.noLoop(); // Ensure shapes are drawn only once
      };

      p.windowResized = () => {
        p.resizeCanvas(p.windowWidth, p.windowHeight);
      };
    };

    class Form {
      constructor(x, y, size, p) {
        this.x = x;
        this.y = y;
        this.size = size;
        this.p = p;
        this.color = this.p.color(255, 227, 232, 150); // Set color to #ffe3e8 with 20% opacity
        this.shapeType = this.p.random(['circle', 'polygon', 'bezier', 'crazyShape']); // Add 'crazyShape' type
      }

      display() {
        this.p.push();
        this.p.translate(this.x, this.y);
        this.p.rotate(this.p.random(this.p.TWO_PI));
        this.p.fill(this.color);

        switch (this.shapeType) {
          case 'circle':
            this.p.ellipse(0, 0, this.size, this.size);
            break;
          case 'polygon':
            this.p.beginShape();
            for (let i = 0; i < 5; i++) {
              const angle = this.p.TWO_PI / 5 * i;
              const x = this.size * this.p.cos(angle);
              const y = this.size * this.p.sin(angle);
              this.p.vertex(x, y);
            }
            this.p.endShape(this.p.CLOSE);
            break;
          case 'bezier':
            this.p.beginShape();
            this.p.vertex(0, 0);
            this.p.bezierVertex(this.size, this.size * 0.5, this.size * -0.5, this.size, 0, this.size * 1.5);
            this.p.endShape();
            break;
          case 'crazyShape':
            this.drawCrazyShape();
            break;
        }

        this.p.pop();
      }

      drawCrazyShape() {
        // A complex shape combining bezier, circle, and triangle
        const halfSize = this.size / 2;

        // Draw a base circle
        this.p.ellipse(0, 0, this.size, this.size);

        // Draw a triangle within the circle
        this.p.beginShape();
        this.p.vertex(0, -halfSize);
        this.p.vertex(halfSize, halfSize);
        this.p.vertex(-halfSize, halfSize);
        this.p.endShape(this.p.CLOSE);

        // Add a bezier curve on top of the circle
        this.p.beginShape();
        this.p.vertex(-halfSize, -halfSize);
        this.p.bezierVertex(-this.size, -this.size, this.size, this.size, halfSize, halfSize);
        this.p.endShape();
      }
    }

    const myP5 = new p5(sketch);
    return () => {
      myP5.remove();
    };
  }, []);

  useEffect(() => {
    // Add background div to block quotes
    const blockQuotes = document.querySelectorAll('.wp-block-quote');
    blockQuotes.forEach((quote) => {
      const backgroundDiv = document.createElement('div');
      backgroundDiv.className = 'quote-background';
      backgroundDiv.style.top = `${Math.random() * 40 - 20}px`; // Increase offset range
      backgroundDiv.style.left = `${Math.random() * 200 - 20}px`; // Increase offset range
      quote.appendChild(backgroundDiv);
    });
  }, [post]);

  if (!post) {
    return (
      <div className="text-center">
        <Spinner animation="border" role="status">
          <span className="sr-only"></span>
        </Spinner>
      </div>
    );
  }

  return (
    <Container>
      <div className='post-wrapper'>
        <h2>{post.title.rendered}</h2>
        <div dangerouslySetInnerHTML={{ __html: post.content.rendered }} className='post-body-text'/>
      </div>
    </Container>
  );
};

export default Post;
